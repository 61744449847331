import VueRouter from 'vue-router';
import Vue from 'vue';
import Login from '../src/components/views/Login.vue'
import Setting from '../src/components/views/Setting.vue'
import Cookies from 'js-cookie'


const routes = [
  {

    path: "/Login",
    name: "Login",
    component: Login,
  },
  {

    path: "/Setting",
    name: "Setting",
    component: Setting,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
});

Vue.use(VueRouter);
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
      if (Cookies.get('token') == null) {
          next('login');
      } else {
          next();
      }
  } else if (to.path == '/login' && Cookies.get('token')) {
      next({
          name: 'Setting'
      });
  } else {
      next();
  }
  next();
})

export default router;
