
import Cookies from 'js-cookie'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
   state:{
    token: Cookies.get('token') || null,
    name: Cookies.get('name'),
    app_url_api: 'http://localhost:8001/api/'
   },
   mutations: {
    SET_TOKEN(state,data) {
        var oneHour = 0.04167;
        Cookies.set('token', data.token, {
          expires: oneHour
        });
    
        Cookies.set('name', data.username)

    },
    REMOVE_COOKIE() {
      Cookies.remove('token')

    },
  },
  actions: {
    setLoginData(context, payload) {
      context.commit('SET_TOKEN', payload)
    },

  },
  getters: {
    isAuthenticated: state => !!state.token,
    token: state=> state.token,
  }
})
