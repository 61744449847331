<template>
  <div class="pa-10">
    <div class="mb-12">Master Mapping Course</div>
    <v-row>
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3">
        Course Id Name</v-col
      >
      <v-col
        v-if="!isUpdate"
        class="align-center d-flex pb-0 pt-0"
        col="12"
        md="3"
      >
        <v-autocomplete
          :items="selectCourseItems"
          item-text="course_name"
          item-value="course_id"
          return-object
          v-model="selectedCourse"
          multiple
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col
        v-if="isUpdate"
        class="align-center d-flex pb-0 pt-0"
        col="12"
        md="3"
      >
        <v-text-field
          v-model="selectedCourseDetail"
          :disabled="isUpdate"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3">
        Cabang</v-col
      >
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3">
        <v-text-field v-model="branch" outlined dense> </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3">
        Jabatan</v-col
      >
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3">
        <v-text-field v-model="position" outlined dense> </v-text-field>
      </v-col>
    </v-row>
    <template>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-row class="mt-5" v-if="!isUpdate">
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3"> </v-col>
      <v-col col="12" md="3" class="d-flex justify-end">
        <v-btn @click="setToSetting"> Simpan</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isUpdate">
      <v-col class="align-center d-flex pb-0 pt-0" col="12" md="3"> </v-col>
      <v-col col="12" md="3" class="d-flex justify-end">
        <v-btn @click="updateSetting">Update</v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between w-100">
      <div>
        <span class="f-14">List Mapping Course </span>
      </div>

      <div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </div>
    </div>
    <v-data-table class="mt-10" :search="search" :items="coursesData" :headers="headers">
      <template v-slot:item.delete="{ item }">
        <v-icon small class="mr-2" @click="getDetailSetting(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  props: {
    source: String,
  },
  created() {
    console.log(process.env.VUE_APP_URL_API);
    this.getAllCourses();
    this.getAllSetting();
  },
  methods: {
    deleteItem(item) {
      this.willBeDeleted = item.id;
      this.dialogDelete = true;
    },
    setToSetting() {
      let courses = [];
      for (let i = 0; i < this.selectedCourse.length; i++) {
        let courseData = this.selectedCourse[i].course_name.split(" -- ");
        let id_number = courseData[0];
        let course_name = courseData[1];
        let course_id = this.selectedCourse[i].course_id;
        courses.push({
          id_number: id_number,
          course_name: course_name,
          course_id: course_id,
        });
      }
      let stringCourse = JSON.stringify(courses);

      let formData = new FormData();
      formData.append("courses", stringCourse);
      formData.append("cabang", this.branch);
      formData.append("jabatan", this.position);
      formData.append("type", "insert");

      axios
        .post(process.env.VUE_APP_URL_API + "saveSetting", formData)
        .then((response) => {
          alert(response.data.message);
          this.getAllSetting();
        });
    },

    getAllCourses() {
      let self = this;
      // console.log(this.$store.getters.token)
      axios
        .get(process.env.VUE_APP_URL_API + "getAllCourses")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            self.selectCourseItems.push({
              course_name:
                response.data[i].id_number + " -- " + response.data[i].course_name,
              course_id: response.data[i].course_id,
            });
          }
        });
    },
    getAllSetting() {
      let self = this;
      self.coursesData = [];
      axios
        .get(process.env.VUE_APP_URL_API + "getAllSetting")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            self.coursesData.push({
              id: response.data[i].id,
              id_number: response.data[i].id_number,
              cabang: response.data[i].cabang,
              jabatan: response.data[i].jabatan,
              course_id: response.data[i].course_id,
              course_name: response.data[i].course_name,
              created_on: response.data[i].created_on,
              created_by: response.data[i].created_by,
            });
          }
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getDetailSetting(item) {
      axios
        .get(process.env.VUE_APP_URL_API + "getDetailSetting/" + item.id)
        .then((response) => {
          console.log(
            response.data.id_number + "-" + response.data.course_name
          );
          this.selectedCourseDetail =
            response.data.id_number + "-" + response.data.course_name;
          this.isUpdate = true;
          this.branch = response.data.cabang;
          this.position = response.data.jabatan;
          this.isUpdateId = response.data.id;
        });
    },
    updateSetting() {
      let formData = new FormData();
      formData.append("id", this.isUpdateId);
      formData.append("jabatan", this.position);
      formData.append("cabang", this.branch);
      formData.append("type", "update");

      axios
        .post(process.env.VUE_APP_URL_API + "saveSetting", formData)
        .then((response) => {
          this.getAllSetting();
          this.isUpdate = false;
          this.branch = "";
          this.position = "";
          console.log(response.data.message);
        });
    },
    deleteItemConfirm() {
      axios
        .post(
          process.env.VUE_APP_URL_API + "deleteSetting/" + this.willBeDeleted,
          " "
        )
        .then((response) => {
          console.log(response.data.message);
          this.getAllSetting();
          this.dialogDelete = false;
        });
    },
  },
  data() {
    return {
      dialogDelete: false,
      selectCourseItems: [],
      coursesData: [],
      willBeDeleted: "",
      isUpdate: false,
      search: "",
      headers: [
        {
          text: "Cabang",
          align: "start",
          sortable: false,
          value: "cabang",
        },
        {
          text: "Jabatan",
          value: "jabatan",
          filter: (value) => {
            if (!this.calories) return true;

            return value < parseInt(this.calories);
          },
        },
        { text: "Course ID Number", value: "id_number" },
        { text: "ID Course", value: "course_id" },
        { text: "Course Name", value: "course_name" },
        { text: "Created On", value: "created_on" },
        { text: "Created By", value: "created_by" },
        { text: "Action", value: "delete" },
      ],
      selectedCourse: "",
      position: "",
      branch: "",
      items: ["Programming", "Design", "Vue", "Vuetify"],
    };
  },
};
</script>

<style></style>
