<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer v-if="$store.getters.isAuthenticated" app clipped>
        <v-list dense>
          <v-list-item to="/Setting" link>
            <v-list-item-action>
              <v-icon>mail</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Master Course</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout" link>
            <v-list-item-action>
              <v-icon>inbox</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar v-show="$store.getters.isAuthenticated" app clipped-left>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <div class="admin-navbar">
            <v-img max-height="85" max-width="100"></v-img>
          </div>
        </v-toolbar-title>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>

      <v-footer app>
        <span>&copy; {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  methods: {
    logout() {
      this.$store.commit("REMOVE_COOKIE");
      this.$router.go({ name: "Login" });
    },
    
    checkAuth() {
      if (this.$store.getters.isAuthenticated) {
        return true;
      }
      return false;
    },
    showNav() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isAuthenticated: this.$store.getters.isAuthenticated,
      drawer:false,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
